import { createBrowserRouter } from "react-router-dom";
import Doc from "../pages/Doc";
import React from "react";

export default createBrowserRouter([
  {
    path: "/:type",
    element: <Doc  />,
  },
]);
